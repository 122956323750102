//@ts-nocheck
import { useEffect } from 'react';
import { footerHtml, indexHtml } from '../static/index'
import $ from 'jquery'

function OldPage() {
    useEffect(() => {
        const script_bootstrap = document.createElement("script");
        script_bootstrap.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js";
        script_bootstrap.async = true;
        document.body.appendChild(script_bootstrap);

        $('.pic, .navbar').mousemove(function (event: any) {
            event.preventDefault();
            var upX = event.clientX;
            var upY = event.clientY;

            const rect = document.querySelector('.cover_effect').getBoundingClientRect();
            var mask = $('#mask1 circle')[0];
            mask.setAttribute("cy", (upY - 5 - rect.top) + 'px');
            mask.setAttribute("cx", (upX) + 'px');
        });

        $('.pic, .navbar').mouseleave(function (event: any) {
            $('#mask1 circle').css('display', 'none')
        });

        $('.pic, .navbar').mouseenter(function (event: any) {
            $('#mask1 circle').css('display', 'block')
        });

        // Navbar shrink function
        var navbarShrink = function () {
            const navbarCollapsible = document.body.querySelector('#mainNav');
            if (!navbarCollapsible) {
                return;
            }
            if (window.scrollY < 1200) {
                navbarCollapsible.classList.remove('navbar-shrink')
            } else {
                navbarCollapsible.classList.add('navbar-shrink')
            }

        };

        // Shrink the navbar 
        navbarShrink();

        // Shrink the navbar when page is scrolled
        document.addEventListener('scroll', navbarShrink);

        // Activate Bootstrap scrollspy on the main nav element
        const mainNav = document.body.querySelector('#mainNav');
        if (mainNav) {
            /*new bootstrap.ScrollSpy(document.body, {
                target: '#mainNav',
                offset: 74,
            });*/
        };

        // Collapse responsive navbar when toggler is visible
        const navbarToggler = document.body.querySelector('.navbar-toggler');
        const responsiveNavItems = [].slice.call(
            document.querySelectorAll('#navbarResponsive .nav-link')
        );
        responsiveNavItems.forEach(function (responsiveNavItem) {
            responsiveNavItem.addEventListener('click', () => {
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });

        $('.small_img').mouseover(function () {
            const selector = '#' + $(this).attr('shows')
            $(selector).css({ 'opacity': 0, 'display': 'block' }).show().animate({ 'opacity': 1 })
        })

        $('.small_img').mouseleave(function () {
            $('#' + $(this).attr('shows')).css('display', 'none')
        })

        // root is the browser viewport / screen
        let observerIDs = {
            "page-top": true,
            "technologies": false,
            "tech1": false,
            "tech2": false,
            "tech3": false,
        }

        //const blurred_div = document.querySelector('#blurred-div');
        const varWidthContainer = document.querySelector("#apple-container")
        //const second_cover = document.querySelector('.cover_second');
        //const cover_img = document.querySelector("#cover-img")
        //const cover_text = document.querySelector(".masthead")

        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(entry => {
                observerIDs[entry.target.id] = entry.isIntersecting
            })
        }, { threshold: [0] });

        for (var observerID in observerIDs) {
            observer.observe(document.querySelector('#' + observerID))
        }

        window.addEventListener('scroll', () => {
            if (observerIDs["page-top"]) {
                const scrollPos = window.scrollY;
                const blurAmount = Math.max(0, 10 - scrollPos / 40)
                //const opacityValue = 2 - scrollPos / 300
                //const brightnessVal = 60 + scrollPos / 30
                const brightnessVal2 = 0.2 + scrollPos / 10
                $('#filter2 fegaussianblur').attr("stdDeviation", 2 * blurAmount / 10);
                $('#filter2 feComponentTransfer').children().attr("slope", Math.min(1, brightnessVal2));
            }

            if (observerIDs.technologies) {
                var element = document.querySelector('#technologies');
                var position1 = element.getBoundingClientRect();
                var diff2 = (250 - position1.top) / 20
                //console.log(diff2)
                var varWidth = Math.max(70, Math.min(100, 70 + diff2));
                varWidthContainer.style.width = varWidth.toString() + "%";

                var position2 = varWidthContainer.getBoundingClientRect();
                var x = position2.top / position2.height;
                //console.log(x)

                if (observerIDs.tech1) {
                    const m1 = Number($("#tech1").attr("rov-m"));
                    const d1 = Number($("#tech1").attr("rov-d"));
                    const value1 = Math.max(0, Math.min(1, m1 * (-1) * (x + d1) * (x + d1) + 1.1));
                    $('#text-tech1').css('opacity', value1)
                }
                if (observerIDs.tech2) {
                    const m2 = Number($("#tech2").attr("rov-m"));
                    const d2 = Number($("#tech2").attr("rov-d"));
                    const value2 = Math.max(0, Math.min(1, -m2 * (x + d2) * (x + d2) + 1.1));
                    $('#text-tech2').css('opacity', value2)
                }
                if (observerIDs.tech3) {
                    const m3 = Number($("#tech3").attr("rov-m"));
                    const d3 = Number($("#tech3").attr("rov-d"));
                    const value3 = Math.max(0, Math.min(1, -m3 * (x + d3) * (x + d3) + 1.1));
                    $('#text-tech3').css('opacity', value3)
                }
            }
        });

        return () => {
            document.body.removeChild(script_bootstrap)
        }
    })

    return (
        <div dangerouslySetInnerHTML={{ __html: indexHtml }}>
        </div>
    )
}

export default OldPage;

export const OldFooter = () => {
    return (
        <div dangerouslySetInnerHTML={{ __html: footerHtml }}>
        </div>
    )
}
