import { Box, Container, Divider, Grid, InputBase, Typography, useTheme } from "@mui/material"
import anidIcon from '../static/assets/imgs/anid.png'
import awsIcon from '../static/assets/imgs/aws.png'
import gcpIcon from '../static/assets/imgs/GCPStartup.jpg'
import notionIcon from '../static/assets/imgs/notion.png'
import santiago from '../static/assets/imgs/santiagocity.jpg'
import { ChangeEvent, useCallback, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { useLazyAddSubscriberQuery } from "../api/landingApi"

const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/

const NewFooter = () => {

    const [trigger, result] = useLazyAddSubscriberQuery()
    const [suscriber, setSuscriber] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const theme = useTheme()


    const validateEmail = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSuscriber(e.target.value)
        if (e.target.value.toLocaleLowerCase().match(emailRegex)) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }
    }, [setValidEmail])

    return <>
        <Container sx={{ marginY: 5, paddingY: 5, textAlign: 'center' }} id="signup">
            <Typography variant="h2" fontWeight="fontWeightBold" textAlign="center">Nos apoyan</Typography>
            <Grid container alignItems="center" sx={{ marginTop: 3 }}>
                <Grid item sm={6} md={3}>
                    <Box component="img" src={anidIcon} sx={{ maxHeight: '20vh', padding: 3 }} />
                </Grid>
                <Grid item sm={6} md={3}>
                    <Box component="img" src={awsIcon} sx={{ maxHeight: '20vh', padding: 3 }} />
                </Grid>
                <Grid item sm={6} md={3}>
                    <Box component="img" src={gcpIcon} sx={{ maxHeight: '20vh', padding: 3 }} />
                </Grid>
                <Grid item sm={6} md={3}>
                    <Box component="img" src={notionIcon} sx={{ maxHeight: '20vh', padding: 3 }} />
                </Grid>
            </Grid>
        </Container>
        <Box
            sx={{
                width: 1,
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 75%, #000 90%),  url(${santiago})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10rem 0',
            }}>
            <Box component="i" className="far fa-paper-plane fa-2x mb-2 text-white" />
            <Typography variant="h4" marginBottom={5} color="white">Suscribete para saber más!</Typography>
            <Container>
                {result.isSuccess
                    ? <Typography variant="h5" textAlign="center" color="white">Ya estas suscrito!</Typography>
                    : <Grid container spacing={5} columns={{ md: 10, lg: 16 }} justifyContent="center" alignItems="stretch">
                        <Grid item md={6} lg={8}>
                            <InputBase sx={{ backgroundColor: 'white', width: 1, height: 1, borderRadius: 1, padding: 2 }} value={suscriber} onChange={validateEmail} type="email" />
                        </Grid>
                        <Grid item md={6} lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 1 }}>
                                <LoadingButton sx={{ height: 1, textAlign: 'center' }} variant="contained" size="large" onClick={() => trigger({ name: '', email: suscriber })} disabled={!validEmail} loading={result.isLoading}>Suscríbete!</LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>}
            </Container>
        </Box>
        <Box
            sx={{
                marginTop: -1,
                width: 1,
                backgroundColor: 'black',
                height: '40vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <Container>
                <Grid container spacing={5} justifyContent={"center"}>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ backgroundColor: 'white', borderRadius: 2, overflow: 'hidden' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20vh', justifyContent: 'center' }}>
                                <Box component="i" className="fab fa-linkedin  text-primary" />
                                <Typography marginBottom={2} marginTop={1} textTransform="uppercase" variant="body2">Linkedin</Typography>
                                <Divider flexItem orientation="horizontal" variant="middle" sx={{ marginX: 20 }} />
                                <Box component="a" href="https://www.linkedin.com/company/rovisen/" marginTop={2}>Rovisen</Box>
                            </Box>
                            <Box sx={{ width: 1, height: '4px', backgroundColor: theme.palette.primary.main }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ backgroundColor: 'white', borderRadius: 2, overflow: 'hidden' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20vh', justifyContent: 'center' }}>
                                <Box component="i" className="fas fa-envelope text-primary" />
                                <Typography marginBottom={2} marginTop={1} textTransform="uppercase" variant="body2">Email</Typography>
                                <Divider flexItem orientation="horizontal" variant="middle" sx={{ marginX: 20 }} />
                                <Box component="a" href="mailto:contacto@rovisen.com" marginTop={2}>contacto@rovisen.com</Box>
                            </Box>
                            <Box sx={{ width: 1, height: '4px', backgroundColor: theme.palette.primary.main }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ backgroundColor: 'white', borderRadius: 2, overflow: 'hidden' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '20vh', justifyContent: 'center' }}>
                                <Box component="i" className="fas fa-mobile-alt text-primary" />
                                <Typography marginBottom={2} marginTop={1} textTransform="uppercase" variant="body2">Teléfono</Typography>
                                <Divider flexItem orientation="horizontal" variant="middle" sx={{ marginX: 20 }} />
                                <Typography marginTop={2}>(+56 9) 5215 0125</Typography>
                            </Box>
                            <Box sx={{ width: 1, height: '4px', backgroundColor: theme.palette.primary.main }} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <footer className="footer bg-black small text-center text-white-50">
                <div className="container px-4 px-lg-5">Copyright &copy; Rovisen 2024 </div>
            </footer>
        </Box>
    </>
}

export default NewFooter